import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import { useTheme, makeStyles } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles(theme => ({
  body: {
    padding: "4.25em 0",
  },
  card: {
    marginLeft: "auto",
    backgroundColor: theme.palette.background.default,
    border: `solid 3px ${theme.palette.secondary.light}`,
    marginTop: ".5rem",
    width: 448,
    height: 636,
    marginRight: "2rem",
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
      width: 470,
      height: 533,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 340,
      marginLeft: "-1.5625rem",
    },
  },
  hr: {
    width: "3em",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    height: ".125rem",
    borderColor: theme.palette.common.blue,
  },
  sectionTitle: {
    ...theme.typography.sectionHeading,
  },

  h4: {
    color: theme.palette.secondary.light,
    textTransform: "uppercase",
    fontSize: "2.5rem",
    fontWeight: 300,
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.95rem",
    },
  },
  imgMobile: {
    [theme.breakpoints.down('md')]: {
      height: 488,
    },
    [theme.breakpoints.down('xs')]: {
      height: 300,
    }
  },

  internalLink: {
    ...theme.typography.body1,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    '&:hover, &:visited, &:active': {
       color: theme.palette.text.primary,
       cursor: 'default',
    },
  }
}))

export default function IndexAbout() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.body}
      >
        <Grid item xs={12}>
          {/* <Grid container direction="row">
            <Grid item xs={12}>
              <Typography
                variant="h4"
                align="center"
                className={classes.h4}
                gutterBottom
              >
                Time Spent in Nature is Time Well Spent
              </Typography>
            </Grid>
          </Grid> */}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ paddingTop: "1.9875em" }}
          >
            <Grid item md={12} lg={6}>
              <Paper className={classes.card} elevation={0}>
                <StaticImage
                  src="../assets/images/Koi-Pond-at-Sunset_Andreatta-Waterscapes_Grants-Pass-Oregon.jpg"
                  height={595}
                  layout="constrained"
                  alt="Koi Pond at Sunset Grants Pass Oregon Andreatta Waterscapes"
                  quality={90}
                  placeholder="blurred"
                  className={classes.imgMobile}
                  // style={{border: `solid 3px ${theme.palette.secondary.light}`, marginTop: '.5rem',}}
                  style={{
                    overflow: "initial",
                  }}
                  imgStyle={{
                    marginLeft: matchesXS ? "1.0625rem" : "1.5625rem",
                    marginTop: matchesXS ? ".85rem" : "1.25rem",
                  }}
                />
              </Paper>
            </Grid>
            <Grid
              item
              md={12}
              lg={6}
              style={{
                paddingTop: matchesMD ? "2em" : undefined,
                paddingLeft: matchesMD ? undefined : "4em",
              }}
            >
              <Grid
                container
                direction="row"
                justify={matchesMD ? "center" : undefined}
              >
                <Grid
                  item
                  xs={10}
                  sm={11}
                  md={9}
                  lg={10}
                  xl={8}
                  // style={{ paddingTop: ".2em" }}
                >
                  <Grid container justify={matchesMD ? "center" : undefined}>
                    <Grid item xs={10} sm={11} md={10} lg={9}>
                      <Typography
                        variant="h4"
                        // align="center"
                        className={classes.h4}
                        gutterBottom
                        align={matchesMD ? "center" : undefined}
                      >
                        Why do you love water?
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        style={{ paddingTop: "1rem" }}
                      >
                        We believe in enhancing your connection to water, nature
                        and each other.
                      </Typography>
                      <Typography variant="body1" paragraph>
                        Our goal is to collaborate with you, which means joining
                        our artistic knowledge and technical expertise with your
                        vision and dreams.
                      </Typography>
                      <Typography variant="body1" paragraph>
                        We focus on{" "}
                        <Link
                          className={classes.internalLink}
                          to="/new-installations/goldfish-ponds/"
                        >
                          natural style ponds,
                        </Link>{" "}
                        <Link
                          className={classes.internalLink}
                          to="/new-installations/waterfalls-streams/"
                        >
                          streams, and waterfalls.
                        </Link>{" "}
                        In addition to building,{" "}
                        <Link
                          to="/repairs-renovations/"
                          className={classes.internalLink}
                        >
                          remodeling,
                        </Link>{" "}
                        and
                        <Link
                          className={classes.internalLink}
                          to="/maintenance/"
                        >
                          {" "}
                          maintaining water features
                        </Link>
                        , we also offer services such as{" "}
                        <Link
                          className={classes.internalLink}
                          to="/new-installations/hardscapes/"
                        >
                          hardscape installations
                        </Link>
                        , landscaping and outdoor project management.
                      </Typography>
                      <Typography variant="body1" paragraph>
                        The services you’ll find throughout these pages all come
                        together to create relaxing outdoor spaces, which
                        provide the quality time and interactions that our
                        customers value.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
