import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Slider from "react-slick"
import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Hidden from "@material-ui/core/Hidden"
import Button from "@material-ui/core/Button"
// import truncate from "lodash.truncate"
// import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt"
// import { alpha } from "@material-ui/core/styles/colorManipulator"

const query = graphql`
  {
    allContentfulTestimonials {
      nodes {
        id
        title
        testimonialBody {
          testimonialBody
        }
        customerName
        customerLocation
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  hr: {
    width: "10em",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderStyle: "solid",
    height: ".135rem",
    // borderColor: theme.palette.common.blue,
    borderColor: theme.palette.common.orange,
    marginTop: ".5em",
    marginBottom: "2em",
  },
  testimonialTitle: {
    marginBottom: 0,
    textTransform: "uppercase",
    fontFamily: ["Work Sans", "sans-serif", '"Helvetica Neue"'].join(","),
    fontWeight: 400,
    fontSize: "1.65rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.25rem",
    },
  },
  readMoreTxt: {
    fontFamily: "Open Sans, sans-serif",
    textTransform: "uppercase",
    fontWeight: 700,
    letterSpacing: .5,
    fontSize: ".905rem",
    '&:hover': {
      cursor: 'pointer',
    }
  },
  readMoreTxtHidden: {
    display: "none",
  },
  testimonialBody: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 300,
    marginBottom: ".5em",
    // marginBottom: "2em",
    // .
    overflow: "hidden",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 3,
  },
  testimonialBodyLong: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 300,
    // marginBottom: "2em",
  },
  testimonialName: {
    fontFamily: ["Lora", '"Helvetica Neue"', "sans-serif"].join(","),
    fontWeight: 900,
    textTransform: "uppercase",
    fontSize: ".905rem",
    marginBottom: 0,
    marginTop: "2em",
  },
  sliderCtr: {
    // paddingTop: "5.625rem",
    paddingTop: "2.925rem",
    maxWidth: "60%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "85%",
      paddingTop: "3rem",
    },
  },
  sliderContentCtr: {
    maxWidth: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "80%",
    },
  },
  slider: {
    "& .slick-arrow": {
      // backgroundColor: theme.palette.common.blue,

      backgroundColor: `rgba(0,0,0,0)`,
      top: "42.5%",
      height: 60,
      width: 40,
      opacity: 0.7,
      zIndex: 5,
      transition: ".25s",
      borderRadius: 5,
      "&:hover": {
        backgroundColor: `rgba(0,0,0,.1)`,
        opacity: 1,
      },
    },
    "& .slick-prev:before, .slick-next:before": {
      // color: theme.palette.common.muted,
      color: `#86868C`,
      opacity: 1,
      "&:active": {
        transform: "scale(1.1)",
      },
    },
  },
  h2: {
    fontSize: "2.5rem",
    marginBottom: 0,
    textTransform: "uppercase",
    [theme.breakpoints.down("xs")]: {
      fontSize: "2rem",
      lineHeight: "2.75rem",
    },
  },

  // h3: {
  //   fontSize: "1.015rem",
  //   marginTop: "1.5rem",
  //   color: alpha(theme.palette.common.muted, 0.9),
  //   textTransform: "none",
  //   [theme.breakpoints.down("xs")]: {
  //     fontSize: "1rem",
  //     lineHeight: "2.75rem",
  //   },
  // },
}))

export default function Testimonials({btn = true}) {
  const classes = useStyles()
  const theme = useTheme()
  const [shorten, setShorten] = React.useState(true)
  const {
    allContentfulTestimonials: { nodes: testimonials },
  } = useStaticQuery(query)
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  let Testimonial = ({
    title = '"Our water feature enriches our lives beyond measure"',
    body = `Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Provident aliquid magni quam perspiciatis in. Atque facilis
            saepe, accusantium in voluptate doloremque neque dolores
            provident amet error, ipsam, voluptatibus similique quasi.`,
    name = "Daryl & Alice",
    attr = "Ashland, Oregon",
  }) => {
    return (
      <Grid
        container
        justifyContent="center"
        className={classes.sliderContentCtr}
      >
        <Grid item xs={12}>
          <Typography
            variant="h5"
            paragraph
            align="center"
            className={classes.testimonialTitle}
          >
            {`"${title}"`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <hr className={classes.hr} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10}>
              <Typography
                variant="body1"
                align="center"
                className={
                  shorten
                    ? classes.testimonialBody
                    : classes.testimonialBodyLong
                }
                gutterBottom
              >
                {body}

                {/* {truncate(body, {
                  length: 250,
                  separator: " ",
                })} */}
              </Typography>
              <Typography
                variant="body1"
                align="center"
                onClick={() => setShorten(false)}
                className={shorten ? classes.readMoreTxt : classes.readMoreTxtHidden}
              >
                Read More
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            paragraph
            align="center"
            className={classes.testimonialName}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            align="center"
            className={classes.testimonialAttr}
          >
            {attr}
          </Typography>
        </Grid>
      </Grid>
    )
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: `${classes.slider}`,
  }
  return (
    <Grid
      container
      direction="row"
      id="testimonials"
      justifyContent="center"
      style={{
        // backgroundColor: "#f8f9fa",
        padding: matchesXS ? "3em 0" : "6.25em 0",
      }}
    >
      <Grid item xs={12}>
        <Hidden smDown>
          <Typography
            variant="h2"
            className={classes.h2}
            align="center"
            paragraph
            color="secondary"
          >
            READ REVIEWS OF ANDREATTA WATERSCAPES
          </Typography>
          {/* <Grid item xs={12} container justifyContent="center">
            <Typography
              variant="h3"
              className={classes.h3}
              align="center"
              paragraph
              component={Link}
              to="/review-us/"
            >
              Or, leave us a review{" "}
              <ArrowRightAltIcon style={{ verticalAlign: "middle" }} />
            </Typography>
          </Grid> */}
        </Hidden>

        <Hidden mdUp>
          <Grid
            item
            xs={10}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Typography
              variant="h2"
              className={classes.h2}
              align="center"
              paragraph
              color="secondary"
            >
              READ REVIEWS OF ANDREATTA WATERSCAPES
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
      <Grid item xs={12} className={classes.sliderCtr}>
        <Slider {...settings}>
          {testimonials.map(testimonial => {
            return (
              <Testimonial
                key={testimonial.id}
                title={testimonial.title}
                name={testimonial.customerName}
                body={testimonial.testimonialBody.testimonialBody}
                attr={testimonial.customerLocation}
              />
            )
          })}
          {/* <Testimonial />
          <Testimonial
            title="Just...beatiful. Honeybee was everything we needed"
            name="Nick Brown"
            attr="CTO of Company B"
          /> */}
        </Slider>
      </Grid>
      {btn && (
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          // style={{ paddingTop: "4.5625rem" }}
          style={{ paddingTop: "4.5625rem" }}
        >
          <Button
            variant="contained"
            color="secondary"
            component={Link}
            to="/review-us/"
          >
            Leave us a review
          </Button>
        </Grid>
      )}
    </Grid>
  )
}
