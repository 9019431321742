import React from "react"

import Layout from "../components/UI/Layout"
import Seo from "../components/Seo"
import HeaderHero from "../components/HeaderHero"
import DynamicNavbar from "../components/UI/DynamicNavbar"
import IndexAboutSection from "../components/IndexAbout"
// import CallToAction from "../components/CallToAction"
// import IndexGallery from "../components/IndexGallery"
import { useTheme } from "@material-ui/core/styles"
import SectionSpacer from "../components/UI/SectionSpacer"
import Testimonials from "../components/Testimonials"
// import NewsletterSignup from "../components/NewsletterSignup"
import ContactCallOut from "../components/IndexContactBtn"
import IndexPicture from "../components/IndexPicture"

const IndexPage = () => {
  const theme = useTheme()
  return (
    <Layout>
      <Seo title="Custom Pond & Water Feature Builder" rating="true"/>
      <DynamicNavbar />
      <HeaderHero />
      <IndexAboutSection />
      {/* <SectionSpacer small={true} /> */}

      {/* <SectionSpacer small={true} /> */}
      <Testimonials btn={false} />
      {/* <NewsletterSignup
        marginTop={theme.spacing(5)}
        marginBottom={theme.spacing(4)}
      /> */}
      <IndexPicture />
      <SectionSpacer small={true} marginTop={theme.spacing(10)} marginBottom={theme.spacing(10)} styling='transparent' />
      <ContactCallOut />

      {/* <CallToAction /> */}
      {/* <IndexGallery /> */}

      {/* <SectionSpacer /> */}
    </Layout>
  )
}

export default IndexPage
