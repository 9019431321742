import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import Divider from "@material-ui/core/Divider"

const useStyles = makeStyles(theme => ({
  hr: {
    backgroundColor: "transparent",
    marginTop: "3.4375rem",
    marginBottom: "3.4375rem",
    marginLeft: "8.75rem",
    marginRight: "8.75rem",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "2rem",
      marginRight: "2rem",
    },
  },
  smallHr: {
    backgroundColor: "transparent",
    marginTop: "1.74375rem",
    // marginBottom: "6.74375rem",
    // marginLeft: "30.75rem",
    // marginRight: "30.75rem",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "50%",
  },
}))

export default function SectionSpacer({ small = false }) {
  const classes = useStyles()
  return (
    <>
      <Divider className={small ? classes.smallHr : classes.hr} />
    </>
  )
}
