import React, { useEffect } from "react"
import PropTypes from "prop-types"
// import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
// import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import HeaderHeroLayout from "./HeaderHeroLayout"
import WaterfallVideo from "../assets/images/waterfallvideo.mp4"
import WaterfallPlaceholder from "../assets/images/waterfallplaceholder.jpg"
// import Hidden from "@material-ui/core/Hidden"

const styles = theme => ({
  button: {
    height: "3.275rem",
    fontFamily: "Roboto, Helvetica Neue",
    fontSize: "1.25rem",
    fontWeight: 600,
    minWidth: 300,
    transition: "0.3s",
    "& :hover": {
      color: "#FFFFFF",
      opacity: 0.8,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      minWidth: 150,
    },
  },
  h1: {
    textShadow: "1px 1px #000",
    fontFamily: "Work Sans, helvetica neue, roboto",
    // fontFamily: "bebas neue, helvetica neue, roboto",
    textTransform: "uppercase",
    // fontSize: "4.6875rem",
    fontSize: "3.751875rem",
    letterSpacing: "-1.35px",
    fontWeight: 400,
    marginTop: '5rem', // compensate for removal of graphic
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '3rem',
    },
  },
  h2: {
    // fontSize: "1.75rem",
    textShadow: "1px 1px #000",
    fontSize: "1.35rem",
    // fontWeight: 400,
    fontWeight: 300,
    marginBottom: ".5625rem",
    marginTop: "0.875rem",
    textTransform: "uppercase",
    // marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(0.5),
    },
  },
  h3: {
    // textShadow: "2px 2px #000",
    textShadow: "1px 1px #000",
    // color: theme.palette.primary.main,
    letterSpacing: "-.8px",
    textTransform: "uppercase",
    // fontSize: "1.5em",
    // fontWeight: 500,
    fontFamily: "Work Sans, helvetica neue, roboto",
    fontSize: "1.2em",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: ".95rem",
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
  dynamicBackground: {
    backgroundImage: `url(${WaterfallPlaceholder})`,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#00171E",
    mixBlendMode: "overlay",
  },
  dynamicVideo: {
    zIndex: -1,
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    opacity: 1,
  },
})

function ProductHero(props) {
  const { classes } = props
  const [initiated, setInitiated] = React.useState(false)

  useEffect(() => {
    setInitiated(true)
  }, [])

  return (
    <HeaderHeroLayout
      backgroundClassName={classes.dynamicBackground}
      homePage={true}
      arrow={false}
    >
      <img
        src={WaterfallPlaceholder}
        style={{ display: "none" }}
        alt="increase priority"
      />
      {/* Increase the network loading priority of the background image. */}

      {!initiated && (
        <img
          src={WaterfallPlaceholder}
          alt="Waterfall Placeholder"
          className={classes.dynamicVideo}
        />
      )}
      {/* <Hidden xsDown> */}
      <video
        src={WaterfallVideo}
        muted
        loop
        autoPlay
        playsInline
        className={classes.dynamicVideo}
      />
      {/* </Hidden> */}
      {/* <Hidden smUp>
        <img
          src={WaterfallPlaceholder}
          alt="waterfall"
          className={classes.dynamicVideo}
        />
      </Hidden> */}
      <Typography
        color="inherit"
        align="center"
        variant="h1"
        marked="center"
        className={classes.h1}
      >
        Andreatta Waterscapes
      </Typography>
      <Typography
        color="inherit"
        align="center"
        variant="h2"
        className={classes.h2}
      >
        Southern Oregon's Premier Pond Builders
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        align="center"
        className={classes.h3}
        gutterBottom
      >
        Serving The Rogue Valley Since 1998
      </Typography>
      {/* <Button
        color="primary"
        variant="contained"
        size="large"
        className={classes.button}
        component={Link}
        to="/contact/"
      >
        Request a Quote
      </Button> */}
    </HeaderHeroLayout>
  )
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired,
  dynamic: PropTypes.string,
}

export default withStyles(styles)(ProductHero)
