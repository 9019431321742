import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.background.default,
    border: `solid 3px ${theme.palette.secondary.light}`,
    borderRadius: 0,
    [theme.breakpoints.down("md")]: {
      marginLeft: "auto",
      marginRight: "auto",
      width: 470,
      height: 533,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 340,
    },
  },
}))


export default function IndexPicture() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} container justifyContent="center">
          <Paper className={classes.card}>
            <StaticImage
              src="../assets/images/Outdoor_Living_Pondless_Waterfall_Andreatta_Waterscapes_Ashland_Oregon_2_600x500.jpg"
              layout="constrained"
              quality={80}
              alt="Outdoor Living Pondless Waterfall Ashland Oregon Andreatta Waterscapes"
              placeholder="blurred"
              imgStyle={{
                marginTop: '.25rem',
                padding: '.95rem',
                // border: '1px solid red'
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
